import React from 'react';
import useGuests from "./Hooks/useGuests";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CreateGuest from "./CreateGuest";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import {isLoading} from "../../Shared/StateHelper";
import {t, wrapper} from "../../I18n/i18n";

const {t: w} = wrapper("MEDICAL_EVENTS.CONSOLE.ADD_GUEST")

const _ = require('lodash');
const AddGuests = ({selectedGuests, onAdd, onDelete}) => {
    const {guests, get, create, state} = useGuests()
    const [value, setValue] = React.useState(null)

    const renderSelectedGuests = () => {
        if (_.isEmpty(selectedGuests)) return <div>
            <h5 className="center">{w("NO_GUEST")}</h5>
        </div>
        return <ul className="collection">
            {selectedGuests.map(guest =>
                <li className="collection-item valign-wrapper" key={`guest_${guest.id}`}>
                    <div className="row" style={{flex: 1}}>
                        <h6 className="col s3">{Helper.formatName(guest.firstname, guest.lastname)}</h6>
                        <h6 className="col s3">{guest.email}</h6>
                        <h6 className="col s3">{guest.telephone}</h6>
                        <h6 className="col s3">{w(`ROLE.${guest.user_type}`)}</h6>
                    </div>
                    <a className="btn red darken-2 z-depth-0" onClick={() => onDelete(guest)}>
                        <i className="material-icons">delete</i>
                    </a>
                </li>
            )}
        </ul>

    }

    return <BorderedDisplay text={"Ajouter des participants"}
                            style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="valign-wrapper" style={{gap: 10}}>
            <h5>{w('SELECT_GUEST')}</h5>
            <Autocomplete
                sx={{flex: 1}}
                disablePortal
                filterSelectedOptions
                loading={isLoading(state)}
                loadingText={`${t("LOADING")}...`}
                noOptionsText={guests === undefined ? t("DO_RESEARCH") : t("NO_RESULT")}
                className="ui-autocomplete"
                value={value}
                options={(guests ?? []).filter(guest => !selectedGuests.some(selected => selected.id === guest.id))}
                getOptionLabel={(orthoptist) => `${Helper.formatName(orthoptist.firstname, orthoptist.lastname)} (${orthoptist.email})`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => {
                    onAdd(value)
                    setValue(null)
                }}
                onInputChange={(_, value) => get({filter: value})}
                renderInput={(params) => <TextField {...params} label={w('SELECT_GUEST')}/>}
            />
        </div>
        <h5 className="center">- {t("OR")} -</h5>
        <div className="valign-wrapper" style={{gap: 40}}>
            <h5>{w('CREATE_GUEST')}</h5>
            <CreateGuest onCreated={onAdd}/>
        </div>

        {renderSelectedGuests()}
    </BorderedDisplay>
}

export default AddGuests;