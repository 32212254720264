import socket from "../../Shared/socket";
import NIDEK_RT_2100_FSM, {PRE_NIDEK_RT_2100} from "../StateMachine/Nidek/NIDEK_RT_2100_FSM";
import NIDEK_RT_3100_FSM, {PRE_NIDEK_RT_3100} from "../StateMachine/Nidek/NIDEK_RT_3100_FSM";
import NIDEK_RT_5100_FSM, {PRE_NIDEK_RT_5100} from "../StateMachine/Nidek/NIDEK_RT_5100_FSM";
import REXXAM_DR900_FSM from "../StateMachine/ShinNippon/REXXAM_DR900_FSM";
import VPRO_VX_60_FSM, {PRE_VPRO_VX_60} from "../StateMachine/Visionix/VPRO_VX_60";

/*
usb engines send an object :
{
    data: string to send,
    waitForResponse: integer, number of seconds to wait for a return on the serial port , 0 means no wait
}
 */

export default function UsbEngines(machine, measures) {
    const serialWriter = (data) => {
        socket.emit("web-to-desktop",
            {
                type: "USB",
                data: data,
                target: machine,
                machine: socket.bridge_uid
            })
    }
    switch (machine) {
        case "Nidek RT-2100":
            return new NIDEK_RT_2100_FSM(measures, serialWriter);
        case "Nidek RT-3100":
            return new NIDEK_RT_3100_FSM(measures, serialWriter);
        case "Nidek RT-5100":
            return new NIDEK_RT_5100_FSM(measures, serialWriter);
        case "Shin Nippon DR-900":
            return new REXXAM_DR900_FSM(measures, serialWriter);
        case "Visionix VX-60":
            return new VPRO_VX_60_FSM(measures, serialWriter);
        case "Pre Nidek RT-2100":
            return {data: PRE_NIDEK_RT_2100(), waitForResponse: 2000};
        case "Pre Nidek RT-3100":
            return {data: PRE_NIDEK_RT_3100(), waitForResponse: 2000};
        case "Pre Visionix VX-60":
            return {data: PRE_VPRO_VX_60(), waitForResponse: 2000};
        default:
            return null;
    }
}