import React, {useEffect, useState} from "react"
import QRCode from "qrcode.react";
import {useParams} from "react-router-dom";
import MedicalEventStore from "../../MedicalEventStore";
import {toast} from "react-toastify";
import VideoPreview from "./VideoPreview";
import ImagePreview from "./ImagePreview";
import Dropzone from "react-dropzone";
import PdfPreview from "./PdfPreview";

const host = process.env.APP_HOST

const Imagery = () => {
    const {appointment_id} = useParams()
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [pdfs, setPdfs] = useState([])

    useEffect(() => {
        getMedia()
        MedicalEventStore.subscribeToCable(appointment_id, getMedia)
        return () => {
            MedicalEventStore.unsubscribeFromCable()
        }
    }, []);

    const getMedia = () => {
        MedicalEventStore.getMedia(appointment_id).then((resp) => {
            setImages(resp.images)
            setVideos(resp.videos)
            setPdfs(resp.pdfs)
        }).catch((_) => toast.error("Erreur lors de la récupération des images/vidéos"))
    }

    const addMedia = (files) => {
        // check if all files are images or videos
        if (!files.every((file) => {
            return file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG") ||
                file.name.includes(".mp4") || file.name.includes(".MP4") || file.name.includes(".mov") || file.name.includes(".MOV") || file.name.includes(".pdf") || file.name.includes(".PDF")

        })) {
            toast.error("Seules les images, vidéos et PDF sont acceptés")
            return
        }

        const promise = MedicalEventStore.addMedia(appointment_id, files)
        toast.promise(promise, {
            pending: "Ajout des images/vidéos...",
            success: "Images/vidéos ajoutées",
            error: "Erreur lors de l'ajout des images/vidéos"
        }).then((resp) => {
            if (!resp) return
            setImages(resp.images)
            setVideos(resp.videos)
            setPdfs(resp.pdfs)
        })
    }

    const removeImage = (link) => {
        MedicalEventStore.removeMedia(appointment_id, link).then((link) => {
            setImages(prevState => prevState.filter((image) => image !== link))
        }).catch((_) => toast.error("Erreur lors de la suppression de l'image"))
    }

    const removeVideo = (link) => {
        MedicalEventStore.removeMedia(appointment_id, link).then((link) => {
            setVideos(videos.filter((video) => video !== link))
        }).catch((_) => toast.error("Erreur lors de la suppression de la vidéo"))
    }

    const removePdf = (link) => {
        MedicalEventStore.removeMedia(appointment_id, link).then((link) => {
            setPdfs(pdfs.filter((pdf) => pdf !== link))
        }).catch((_) => toast.error("Erreur lors de la suppression du PDF"))
    }

    return <div style={{display: "flex", gap: 10, flexWrap: "wrap"}}>
        <div style={{display: "flex", flexDirection: "column", maxWidth: 200, gap: "0.5rem"}}>
            <Dropzone onDrop={addMedia}>
                {({getRootProps, getInputProps, isDragActive}) => <div {...getRootProps()} className="clickable">
                    <input {...getInputProps()} />
                    {isDragActive ? <div style={{
                            height: 200,
                            width: 200,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "thin solid #dddddd",

                        }}>
                            <i className={"material-icons"}>cloud_upload</i>
                        </div> :
                        <QRCode
                            value={`${host}/orthoptistes/phone/${appointment_id}?uuid=${MedicalEventStore.event.uuid}`}
                            size={200} bgColor={isDragActive ? "#dddddd" : "white"}/>
                    }

                </div>
                }
            </Dropzone>
            <label className="center" style={{fontSize: "0.7rem", lineHeight: "0.7rem"}}>
                Scanner ce QR code pour importer des images depuis votre mobile.
                <br/>Ou cliquez/déposez pour ajouter des images/vidéos.
            </label>
        </div>
        <div style={{flex: 1, display: "flex", flexWrap: "wrap", gap: 10}}>
            {images.map((image, index) => <div key={`image_preview_${index}`}
                                               style={{
                                                   width: 100,
                                                   height: 100,
                                                   borderRadius: 10,
                                                   backgroundColor: "black",
                                                   overflow: "clip",
                                               }}>
                <ImagePreview id={`image_preview_${index}`} src={image} style={{maxWidth: 100, maxHeight: 100}}
                              onRemove={() => removeImage(image)}/>
            </div>)}
            {videos.map((video, index) => <div key={`video_preview_${index}`} style={{
                width: 100, borderRadius: 10, display: "flex", justifyContent: "center",
                backgroundColor: "black", height: 100, overflow: "clip"
            }}>
                <VideoPreview id={`video_preview_${index}`} src={video} onRemove={() => removeVideo(video)}
                              style={{width: 100, maxHeight: 100, fitContent: "cover"}}/>
            </div>)}
            {pdfs.map((pdf, index) => <div key={`pdf_preview_${index}`} style={{
                width: 100, borderRadius: 10, display: "flex", justifyContent: "center",
                backgroundColor: "black", height: 100, overflow: "clip"
            }}>
                <PdfPreview id={`pdf_preview_${index}`} src={pdf} onRemove={() => removePdf(pdf)}/>
            </div>)}
        </div>
        <a className="btn-small blue darken-2 z-depth-0" title={"Rafraichir les images/vidéos"}
           onClick={getMedia}>
            <i className="material-icons">refresh</i>
        </a>
    </div>
}

export default Imagery