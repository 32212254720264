import React from 'react';
import NavBar from "./NavBar";
import MessengerButton from "../../Messenger/MessengerButton";
import NotificationSlider from "../../Notifications/Slider/NotificationSlider";
import UserDropdown from "./UserDropdown";
import {getEnv} from "../Helper";
import {wrapper} from '../../I18n/i18n'

export default function OrthoptistNavbar({image_path, user, configuration, topbar_prop = "", status}) {
    const {c: t} = wrapper("NAVBAR");
    const {teletransmission_enabled, perform_events} = configuration
    const isWhite = getEnv("BRAND") === "WHITE"
    const components = [
        {component: <MessengerButton/>, dataCy: "MessengerButton"},
        {component: <NotificationSlider user={user}/>, dataCy: "NotificationSlider"},
        {component: <UserDropdown user={user}/>, dataCy: "UserDropdown"},
    ]

    const urls = [
        {url: "/notifications/index", name: t("ALERTS")},
        {url: "/agenda", name: t("AGENDA")},
        {url: "/patients", name: t("PATIENTS")},
        {url: "/orthoptistes/teletransmissions", name: t("TELETRANSMISSION"), show: teletransmission_enabled && !isWhite},
        {url: "/orthoptistes/medical_events", name: t("MEDICAL_EVENTS"), show: perform_events},
        // {
        //     name: t("OTHER"),
        //     dropdown: [
        //         // {url: "/messages/orthoptiste/conversations", name: t("MESSAGES")},
        //         // {url: "/orthoptistes/paiements/overview", name: t("INVOICES")},
        //         // {url: "/orthoptistes/statistics", name: t("STATISTICS"), show: !isWhite},
        //         // {url: "/orthoptiste", name: t("DOWNLOAD_PRESCRIPTIONS"), show: !isWhite},
        //         // {url: "/orthoptistes/validator", name: t("VALIDATE")},
        //         // {url: "/refractometres", name: t("REFRACTOMETER")},
        //         // {url: "/orthoptistes/reports", name: t("REPORTS")}
        //     ]
        // }
    ]

    return <NavBar image_path={image_path} urls={urls} topbar_prop={topbar_prop} components={components} status={status}/>
}
