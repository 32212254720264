import React from 'react';
import useMedicalEvents from "./Hooks/useMedicalEvents";

import {Link} from "react-router-dom";
import LinkHandler from "./LinkHandler";
import {MedicalEventStatus} from "../../admin/Console/MedicalEvents/Enums/Enums";
import {hasFailed, isLoading} from "../../Shared/StateHelper";
import Error from "../../Shared/Error";
import Loader from "../../Shared/Loader";

const MedicalEvents = () => {
    const {medicalEvents, state} = useMedicalEvents();

    const renderStatus = (status) => {
        switch (status) {
            case MedicalEventStatus.SCHEDULED:
                return <i className="material-icons grey-text" title={"L'évènement est en attente"}>schedule</i>
            case MedicalEventStatus.IN_PROGRESS:
                return <i className="material-icons orange-text" title={"L'évènement est en cours"}>pending</i>
            case MedicalEventStatus.COMPLETED:
                return <i className="material-icons green-text" title={"L'évènement est terminé"}>done</i>
            case MedicalEventStatus.CANCELED:
                return <i className="material-icons red-text" title={"L'évènement est annulé"}>error</i>
            default:
                return null
        }
    }

    const renderEvents = () => {
        if (isLoading(state)) return <div style={{marginTop: 100}}><Loader text="" size="small"/></div>
        if (hasFailed(state)) return <div style={{marginTop: 100}}>
            <Error errorText="Impossible de charger les évènements"/>
        </div>
        if (medicalEvents.length === 0) return <div style={{marginTop: 100}}>
            <h3 className="center-align">Aucun évènement n'est à venir</h3>
        </div>

        return <div style={{margin: 20}}>
            <ul className="collection">
                {medicalEvents.map(event => {
                    const date = event.date ? Helper.formatDate(event.date, "DD/MM/YYYY hh:mm") : "Date à définir"
                    return <li className="collection-item valign-wrapper" key={event.id}>
                        <div className="row valign-wrapper" style={{flex: 1}}>
                            <div className="col s1">
                                <span className="valign-wrapper">{renderStatus(event.status)}</span>
                            </div>
                            <div className="col s2"><span className="title">{date}</span></div>
                            <div className="col s9"><span className="title">{event.name}</span></div>
                        </div>
                        <LinkHandler event={event}/>
                    </li>
                })}
            </ul>
        </div>
    }

    return <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
        <div className="valign-wrapper">
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">calendar_month</i>
                Évènements
            </h3>
            <Link className="btn blue darken-2 z-depth-0"
                  style={{marginInline: 20, marginLeft: "auto"}}
                  to={"/new"}
            >
                <i className="material-icons left">add</i>
                Nouvel évènement
            </Link>
        </div>

        <div className="divider"/>
        <div style={{marginLeft: 2}}>
            {renderEvents()}
        </div>
    </div>
}

export default MedicalEvents;