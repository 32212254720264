import React from "react"
import {HashRouter as Router, Route, Routes} from "react-router-dom"
import {observer} from "mobx-react";
import MedicalEvents from "./MedicalEvents";
import CreateMedicalEvent from "./CreateMedicalEvent";


const MedicalEventsRouter = observer(() => {

    return <div id="in-progress-event-view">
        <Router>
            <Routes>
                <Route path="/" exact element={<MedicalEvents/>}/>
                <Route path="/new" exact element={<CreateMedicalEvent/>}/>
            </Routes>
        </Router>
    </div>
})

export default MedicalEventsRouter