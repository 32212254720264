import React, {useEffect, useState} from 'react';

import AddGuests from "./AddGuests";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const MedicalEventForm = ({medicalEvent, onChange}) => {
    const [ophtalmologists, setOphtalmologists] = useState([]);

    useEffect(() => {
        getOphtalmologists()
    }, []);

    const getOphtalmologists = () => {
        axios.get("/orthoptistes/medical_events/ophtalmologists")
            .then(resp => {
                setOphtalmologists(resp.data)
                if(resp.data?.length === 1) onChange({...medicalEvent, ophtalmologiste_id: resp.data[0].id})
            })
    }

    return <div className="col s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
        <div className="input-field outlined col s12">
            <input id="name" type="text" value={medicalEvent.name}
                   onChange={(e) => onChange({...medicalEvent, name: e.target.value})}/>
            <label htmlFor="name">Nom de l'évènement</label>
        </div>
        <div className="input-field outlined col s12">
            <textarea id="description" value={medicalEvent.description}
                      style={{height: 100, paddingBlock: 10, resize: "none"}}
                      onChange={(e) => onChange({...medicalEvent, description: e.target.value})}/>
            <label htmlFor="name">Description de l'évènement</label>
        </div>
        <div className="row" style={{gap: 20}}>
            <div className="input-field outlined col s6">
                <input id="date" type="date" value={medicalEvent.date} min={new Date().toISOString().split("T")[0]}
                       onChange={(e) => onChange({...medicalEvent, date: e.target.value})}/>
                <label htmlFor="date">Date de l'évènement</label>
            </div>
            <div className="input-field outlined col s6">
                <input id="time" type="time" value={medicalEvent.time}
                       onChange={(e) => onChange({...medicalEvent, time: e.target.value})}/>
                <label htmlFor="time">Heure de l'évènement</label>
            </div>
        </div>
        <div className="row" style={{gap: 20}}>
            <div className="col s6">
                <Autocomplete
                    sx={{width: "100%"}}
                    disablePortal
                    // disabled={disabled}
                    filterSelectedOptions
                    className="ui-autocomplete"
                    value={ophtalmologists.find(o => o.id === medicalEvent.ophtalmologiste_id) ?? null}
                    options={(ophtalmologists ?? [])}
                    getOptionLabel={(ophtalmo) => `${Helper.formatName(ophtalmo.firstname, ophtalmo.lastname)}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, value) => onChange({...medicalEvent, ophtalmologiste_id: value.id})}
                    renderInput={(params) => <TextField {...params} label="Sélectionner ophtalmologiste"/>}
                />
            </div>
        </div>
        <div style={{paddingTop: 20}}>
            <AddGuests selectedGuests={medicalEvent.guests}
                       onAdd={(guest) => onChange({...medicalEvent, guests: [...medicalEvent.guests, guest]})}
                       onDelete={(guest) => onChange({
                           ...medicalEvent,
                           guests: medicalEvent.guests.filter(g => g.id !== guest.id)
                       })}/>
        </div>

    </div>
}

export default MedicalEventForm;