import {observer} from "mobx-react";
import React, {useEffect} from "react";

const PdfDiplayPanel = ({pdfs}) => {

    if (pdfs.length === 0) return null

    useEffect(() => {
        // Modal must tale all height
        const modals = document.querySelectorAll('.modal');
        M.Modal.init(modals, {
            endingTop: "2%",
            onOpenEnd: function(modal, trigger) {
                modal.style.maxHeight = "100%";
                modal.style.height = "90%";
                modal.style.display = "flex";
                modal.style.flexDirection = "column";
            }
        });

        return () => {
            modals?.forEach(modal => {
                M.Modal.getInstance(modal)?.destroy()
            })
        }
    }, []);

    const renderPdfModal = (pdf, index) => {
        // Modal must tale all height
        return <div id={`pdf-modal-${index}`} className="modal"
                    style={{height: "90%", maxHeight: "100%!important"}}>
            <div className="modal-content" style={{flex: 1}}>
                <h4>{pdf.name}</h4>
                <iframe src={pdf} width="100%" height="95%"/>
            </div>
            <div className="modal-footer">
                <a className="modal-close waves-effect waves-green btn-flat">Fermer</a>
            </div>
        </div>
    }
    return (
        <div className="card-panel black" style={{borderRadius: '30px'}}>
            {
                pdfs.map((pdf, index) => (
                    <div key={index} className="col s12">
                        <a className="clickable grey darken-2 modal-trigger" data-target={`pdf-modal-${index}`}
                           style={{
                               width: 100,
                               height: 100,
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               borderRadius: 5
                           }}>
                            <i className="material-icons white-text">picture_as_pdf</i>
                        </a>
                        {renderPdfModal(pdf, index)}
                    </div>
                ))
            }
        </div>
    )
}

export default PdfDiplayPanel