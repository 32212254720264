import React from 'react';

const PdfPreview = ({id, src, onRemove}) => {
    const title = src.split('/').pop().replace(/%20/g, ' ')
    return <div style={{position: "relative"}}>
        <a href={src} target="_blank" rel="noreferrer" className="clickable"  title={title}
           style={{width: 100, height: 100, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <i className="material-icons white-text">picture_as_pdf</i>
        </a>
        <i className={`material-icons tiny red-text clickable`}
           style={{position: "absolute", top: 4, right: 4, color: "white"}}
           onClick={e => {
               e.stopPropagation()
               onRemove()
           }}>
            cancel
        </i>
    </div>

}

export default PdfPreview;